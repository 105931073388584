import axios from "axios";
import { useRef, useState } from "react"
import { useParams } from "react-router-dom"


export default function Recovery(){
    const {id, code} = useParams()
    const [credentials, SetCredentials] = useState({
        username: id,
        codeRecovery: code
    });
    const [inSubmit, SetInSubmit] = useState(false);
    const alert = useRef();

    function Submit(e){
        e.preventDefault()

        alert.current.style.display = "none";

        if(!inSubmit){
            SetInSubmit(true)
            axios.post("https://figestor.herokuapp.com/auth/recovery", {
                ...credentials
            }).then(d=>{
                alert.current.style.display = "flex";
                alert.current.classList.remove("danger");
                alert.current.classList.remove("success");
                
                if(d.data.err){
                    alert.current.classList.add("danger");
                    alert.current.innerHTML = d.data.msg;
                    return
                }
    
                else{
                    alert.current.classList.add("success");
                    alert.current.innerHTML = "Senha alterada com sucesso!";
                }
            }).catch(err=>{
                console.log(err);
                alert.innerHTML = "Erro interno ao salvar sua nova senha!";
            }).finally(()=>{
                SetInSubmit(false)
            })
        }
        
    }

    function AddOnCredential(key, v){
        SetCredentials({
            ...credentials,
            [key]: v
        })
    }

    return (
        <section className="sectionCredits">
            <h2>Recupere sua senha</h2>
            <small>
                Se precisar de ajuda chame <a target={"_blank"} href="https://www.instagram.com/fox.developer/" rel="noreferrer">aqui</a>
                <br/>
                Qualquer dúvida leia a <a target={"_blank"} href="/policy">documentação</a>.
            </small>

            <div ref={alert} className="flex f-center alert">

            </div>

            <form onSubmit={Submit} className="flex f-center column-f">
                <label>Sua nova senha: </label>
                <input
                    required={true}
                    onChange={(v)=> AddOnCredential("new_password", v.target.value)}
                    type="password"
                />
                <label>Confirme a sua nova senha</label>
                <input
                    required={true}
                    onChange={(v)=> AddOnCredential("conf_password", v.target.value)}
                    type="password"
                />
                <label>Seu ID de Usuário</label>
                <input
                    required={true}
                    onChange={(v)=> AddOnCredential("username", v.target.value)}
                    defaultValue={credentials.username}
                    type="text"
                />
                <label>Seu código de recuperação</label>
                <input
                    required={true}
                    defaultValue={credentials.codeRecovery}
                    onChange={(v)=> AddOnCredential("codeRecovery", v.target.value)}
                    type="text"
                />
                <button onClick={Submit} type="submit">Mudar senha</button>
            </form>
        </section>
    )
} 