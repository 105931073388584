import { Link } from "react-router-dom";
import DownloadButton from "../fragments/Download";
import Styles from "../module/Home.module.css";

export function Home(){
    return (
        <section className={`flex column-f ${Styles.mainCard}`}>
            <h1>FiGestor - Projeto</h1>
            <article>
                <h2>Proposta do Projeto</h2>
                <p className="content">
                    O Projeto FiGestor é um app para android com o objetivo de auxiliar o usuário no controle de gastos, 
                    no nome, a parte <strong>Fi</strong> vem de <em>finanças</em> e
                    <strong> Gestor</strong> de <em>Gerenciamento</em>.   
                </p>
                <p className="content">
                    Na pre-elaboração do projeto a proposta inicial seria ser um gerenciador, porém,
                    foi dada a ideia de fazer uma área para planejamento de gastos,
                    o que resultou no uso para orçamentos. A mesma serve para planejar com o que irá gastar;
                    exemplo: uma lista de compras mensais. Você coloca os itens necessários, o valor e quantidade do item, 
                    e terá o valor total dos gastos.
                </p>
                <p className="content">
                    Terá somente a versão mobile (android) como o app em si, a versão web(esta) 
                    será apenas para aviso de atualização, downloads, e <Link to="/policy">regulamentos</Link>(politica de serviços e privacidade);
                    Esta página tem emissor de notificações, caso tenha interesse em receber notificações, aceite clicando em "Subscribe". 
                    Caso não apareça, você está usando um navegador com bloqueador(como o brave), apenas desativar o bloqueador neste site. 
                </p>
                <p className="content">
                    O App não será publicado na Google Play neste momento pois devido eu ser "de menor" ainda não me autorizam ter uma conta de desenvolvedor no Google,
                    mas em breve o app será publicado lá;
                    Quanto a App Store, manter um app é muito caro, custa 99$(dólares) anuais, e para Google Play, 25$(dólares) apenas no cadastro.
                </p>
            </article>
            <article>
                <h2>Download</h2>
                <DownloadButton
                    file={require("../../apks/alpha/figestor-1.2.apk")}
                    info={"Bug Corrigido."}
                    version={"1.3.0"}
                    name="figestor-1.3.apk"/>
                <DownloadButton
                    file={require("../../apks/alpha/figestor-1.1.apk")}
                    info={"Adição filtro mes/ano\nRelato mês ano."}
                    version={"1.2.0"}
                    name="figestor-1.2.apk"/>
                <DownloadButton
                    file={require("../../apks/alpha/figestor-1.0.apk")}
                    info={"Primeira versão\nAdição de gerenciamento de gastos\nCrie planejamentos/orçamentos."}
                    version={"1.0.0"}
                    name="figestor-1.0.apk"/>
            </article>
        </section>
    )
}