import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import data from "../../data.json";


// export function BetaEx(){
//     const history = useNavigate();
//     const [code, SetCode] = useState("");
//     const [codeInpt, SetCodeInpt] = useState("");

    
//     useEffect(()=>{
//         if(code){
//             if(!data.codeBeta.includes(code)){
//                 history("/");
//             }
//         }

//     // eslint-disable-next-line
//     }, [code])

//     return code? (
//         <section className="sectionCredits">
//             <h2 className="content">Versão Beta</h2>
//             <p className="content">
//                 Você foi escolhido para ter acesso a versão beta, clique no botão abaixo para baixa-lá.
//             </p>
//             <button 
//                 onClick={()=>{
//                     const link = document.createElement("a");
//                     link.setAttribute("href", require("../../apks/beta/figestor-1.1.3BETA.apk"))
//                     link.setAttribute("download", "figestor-1.1.3BETA.apk");

//                     link.click()

//                     link.remove()
//                 }}
//                 className="download">Download 1.1.3</button>
//             <button 
//                 onClick={()=>{
//                     const link = document.createElement("a");
//                     link.setAttribute("href", require("../../apks/beta/figestor-1.1.2BETA.apk"))
//                     link.setAttribute("download", "figestor-1.1.2BETA.apk");

//                     link.click()

//                     link.remove()
//                 }}
//                 className="download">Download 1.1.2</button>
//             <button 
//                 onClick={()=>{
//                     const link = document.createElement("a");
//                     link.setAttribute("href", require("../../apks/beta/figestor-1.1.0BETA.apk"))
//                     link.setAttribute("download", "figestor-1.1.0BETA.apk");

//                     link.click()

//                     link.remove()
//                 }}
//                 className="download">Download 1.1</button>
//             <button 
//                 onClick={()=>{
//                     const link = document.createElement("a");
//                     link.setAttribute("href", require("../../apks/beta/figestor-1.0.0BETA.apk"))
//                     link.setAttribute("download", "figestor-1.0.0BETA.apk");

//                     link.click()

//                     link.remove()
//                 }}
//                 className="download">Download 1.0</button>
//             <button 
//                 onClick={()=> window.open("https://wa.me/5574999117471")}
//                 className="download feedback">FeedBack</button>
//         </section>
//     ): <section className="sectionCredits flex f-center column-f">
//         <p className="content">
//             Esta página é restrita, para ter acesso a ela tem que ter um código.
//         </p>
//         <form onSubmit={(e)=>{
//             e.preventDefault();
//             SetCode(codeInpt);
//         }} className="flex column-f f-center">
//             <h3>Acesse</h3>
//             <input
//                 onChange={(e)=> SetCodeInpt(e.target.value)}
//                 type={"text"}
//                 placeholder="Código de acesso"
//                 />
//             <button type="submit">Ativar página</button>
//         </form>
//     </section>
// } 

export default function Beta(){
    return (
        <div className="sectionCredits">
            <p>Programa beta expirou.</p>
        </div>
    )
}