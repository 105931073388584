import axios from "axios";
import { useRef, useState } from "react"
import { useParams } from "react-router-dom"


export default function ActiveAccount(){
    const {id, code} = useParams()
    const [credentials, SetCredentials] = useState({
        username: id,
        codeActive: code
    });
    const [inSubmit, SetInSubmit] = useState(false);
    const alert = useRef();

    function AddOnCredential(key, v){
        SetCredentials({
            ...credentials,
            [key]: v
        })
    }

    async function Submit(e){
        e.preventDefault()

        alert.current.style.display = "none";

        if(!inSubmit){
            SetInSubmit(true)
            const res = await axios.post("https://figestor.herokuapp.com/auth/active", {
                ...credentials
            });

            alert.current.style.display = "flex";
            alert.current.classList.remove("danger");
            alert.current.classList.remove("success");
            
            if(res.data.err){
                alert.current.classList.add("danger");
                alert.current.innerHTML = res.data.msg;
                return
            }

            else{
                alert.current.classList.add("success");
                alert.current.innerHTML = "Conta ativada com sucesso!";
            }

            SetInSubmit(false);
        }
        
    }
    return (
        <section className="sectionCredits">
            <h2>Ative sua conta</h2>

            <div ref={alert} className="flex f-center alert">

            </div>

            <form onSubmit={Submit} className="flex f-center column-f">
                <label>Seu ID de Usuário</label>
                <input
                    required={true}
                    onChange={(v)=> AddOnCredential("username", v.target.value)}
                    defaultValue={credentials.username}
                    type="text"
                />
                <label>Seu código de ativação</label>
                <input
                    required={true}
                    defaultValue={credentials.codeActive}
                    onChange={(v)=> AddOnCredential("codeActive", v.target.value)}
                    type="text"
                />
                <button type="submit">Ativar conta</button>
            </form>
            
        </section>
    )
} 