import { useState } from "react"

function Account(){
    return (
        <div id="account">
            <h2>Politica de Conta</h2>
            <h3>Por que exigimos uso de uma conta?</h3>
            <p className="content">
                O nosso serviço é 100% online, e por isso exigimos que você usuário de tenha uma conta,
                isso facilita também nossa forma de armazenamentos, pois as informações são armazenadas no servidor, e não no seu celular,
                evitando que você use ocupe muito espaço no seu smartphone com este app. Isso ajuda também na troca de dispositivo,
                pois se trocar de dispositivo poderá ter estas informações no outro, apenas conectando a conta.
            </p>
            <p className="content">
                O seu dado de email é importante apenas para recuperação da conta. 
            </p>
            <h3>Criando a conta</h3>
            <p className="content">
                Ao abrir o app pela primeira vez você verá a tela de <em>login</em>, nela teremos um botão azul que leva a página de registro, e fazer este registro é super simples,
                apenas por um nome de identificação(tipo o @ do Instagram), ele servirá para tu como um "email" para fazer o login da sua conta.
                <em> Importante falar que ele não pode ter espaços nem letras maiúsculas, mas claro, este app foi desenvolvido para poder ajustar isso(ele converte o texto, exemplo: "Jefferson dev" para "jefferson_dev").</em>
                <br/>
                Seguindo a diante ele pede seu nome, nome esse que não precisa ser um nome real, apenas um nome para display;
                O nome não pode conter teor pornográfico, ou insultos, tão pouco palavras de baixo calão.
                <br/>
                Caso na lista da ne usuário seu nome quebre um desses requisitos, sua conta pode ser deletada.
                <br/>
                E por fim pedimos a senha, e a confirmação dessa senha, ela deve ter no mínimo 6 caracteres, não é obrigado seguir outro requisito além desse.
                Caso esqueça a senha não adianta pedir aos admin para lhe dizer qual é, pois a senha ela é salva de forma codificada, isso é, invés de 123, é "#5#2e32ds%6$", um texto literalmente ilegível a leitura humana, que apenas o sistema de login pode decifrar.
            </p>
            <h3>Editar conta</h3>
            <p className="content">
                Ao apertar no botão do bonequinho você será redirecionado para o lugar de edição da conta, o único dado que tu pode editar é seu nome.
            </p>
            <h3>Minha conta desconectou só, o que pode ser?</h3>
            <p className="content">
                O motivo para isso acontecer o seu token de autenticação está desatualizado, ou houve algum erro na hora de carregar suas informações; O app busca suas informações a cada 15 minutos,
                para manter seu uso atualizado, em caso de erro ou token desatualizado a muitos dias, você será desconectado. <em>Como atualizar o token? </em> Como dito, só será desconectado se ficar muitos dias desatualizado, 
                o token tem a validade de 30 dias, e na obtenção de dados, se ele tiver invalido será atualizado automaticamente(caso não tenha passado 60 dias ou mais), tirando isso, única forma de você atualizar, é fazendo o login novamente. 
            </p>
            <h3>Não se envolemos no que não é da nossa conta.</h3>
            <p className="content">
                Nós são puxamos suas informações para sabermos suas informações de gastos e ganhos, ou planejamentos, enfim.
                Qualquer coisa que se aplique "cheretar" o que não é nosso, não nos é permitido, e tão pouco há importância para nós.
            </p>
        </div>
    )
}

function RecoveryPass(){
    return (
        <div id="recoveryPass">
            <h2>Esqueceu a senha?</h2>
            <p className="content">
                O app não exige email para seu login, ou cadastro, então a recuperação de senha foi feita de um modo um tanto que complicada;
                Caso esteja logado, você deverá ir na área de edição de perfil, e apertar em "Mudar senha", digite sua senha atual, a nova senha, e repita a nova, confirme-a, aperte o botão "Confirmar", e pronto!
                <br/>
                Caso não esteja logado na tela de login tem um botão "Esqueci a senha", aperte e será redirecionado a um formulário onde pede seu email, digite, e ele irá emitir um email para você, com o código de recuperação, clique em avançar, digite o código e o seu userId, e digite sua nova senha.
            </p>
        </div>
    )
}

function DashboardInfo(){
    return (
        <div>
            <h2>Gerencie Sua Renda</h2>
            <p className="content">
                Ao conectar-se a sua conta, o menu inicial será o dashboard da sua conta, nele aparecerá suas informações financeiras;
                você verá dois botões, um de ganho e um de gasto, e ao apertar lhe aparecerá um pequeno formulário, que lhe pede o valor, e a informação sobre.
            </p>
            <h3>Sobre informações</h3>
            <p className="content">
                O input de informação é opcional, nele você vai colocar informação sobre este saldo ganho/gasto, exemplo: 
                Ganhei salario semanal; ou: Gastei com as compras mensais.
            </p>
        </div>
    )
}

function Plans(){
    return (
        <div>
            <h2>Sobre planejamentos</h2>
            <p className="content">
                Quer fazer planejamentos e ter uma base total do que vai gastar com ele? Essa função foi pensada no pensamento de criar uma lista para compra mensais,
                mas analisando melhor, foi adicionado como forma de fazer orçamentos.  
            </p>
            <h3>Crie um planejamento</h3>
            <p className="content">
                Ao apertar no ícone de papelzinho no canto inferior, você será redirecionado com a lista de planejamentos, e nele terá o botão de criar;
                Apertando nele será redirecionado ao formulário para criar seu planejamento, exigindo dois valores, sendo o nome um valor obrigatório, e informações(opcional),
                terá o botão de adicionar item, e quando você clica no botão de adicionar um item, aparecerá um pequeno formulário para adicionar o item, exigindo valor(numérico), nome e quantidade(numérico).
            </p>
            <p className="content">
                Após preencher tudo, aperte o botão "Adicionar item", e o item será adicionado ao planejamento.
                Ao adicionar o item terá a opção de editar ou remover(vide tópico de sobre gerenciamento de itens). Por fim, aperte em criar planejamento, 
                e o planejamento será criado, e você poderá ver na lista e as informações dele. Na página de informação do planejamento, terá a opção de adicionar e remover itens.
            </p>
            <h3>Gerenciamento de Itens</h3>
            <p className="content">
                Tanto na página de criação como na página de informação você pode adicionar, editar, ou remover um item.
                <br/>
                informações
                <ul style={{margin: 15}}>
                    <li>
                        Valor
                        <dl>Valor numérico - Preço do item, exemplo: 2,5</dl>
                    </li>
                    <li>
                        Quantidade
                        <dl>Valor numérico - Unidade do item, exemplo: 2</dl>
                    </li>
                    <li>
                        Nome
                        <dl>Nome/descrição do item, exemplo: Água mineral 500ml</dl>
                    </li>
                </ul> 
            </p>
            <h4>Adicionar</h4>
            <p className="content">
                Clique no botão "Adicionar item" e aparecerá um formulário para você preencher, com os valores citado acima.
                Após preencher tudo, aperte o botão "Adicionar item", e o item será adicionado ao planejamento.
                Ao adicionar o item terá a opção de editar ou remover.
            </p>
            <h4>Editar</h4>
            <p className="content">
                Clique no botão que tem um lapisinho que aparece na lista, aparecerá um formulário preenchido para você modifica, com os valores citado anteriormente.
                Após preencher tudo, aperte o botão "Adicionar item", e o item será editado.
                Ao adicionar o item terá a opção de editar ou remover.
            </p>

            <h4>Editar</h4>
            <p className="content">
                Clique no botão que tem uma lixeira, após apertar ele irá pedir uma confirmação, se confirmar, o item será deletado.
            </p>
            <h3>Comparação de planos</h3>
            <p className="content">
                Você quer saber quais dos planejamentos é mais barato? Na página onde tá a lista de planejamento tem um botão "Modo Comparação", aperte nele,
                e após isso aperte em dois dos planos que deseja comparar, e aperte o botão "Comparar", e você será redirecionado a página de comparação.
            </p>
        </div>
    )
}



export default function Policy(){
    const [view, SetView] = useState("main")
    return (
        <section className="sectionCredits">
            <h1>
                Documentação e regulamentos
            </h1>
            <table className="table">
                <caption>
                    Index
                    <br/>
                </caption>
                <thead >
                    <tr>
                        <th>Assunto</th>
                        <th>Resumo</th>
                        <th>Tipo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr onClick={SetView.bind(this, "account")}>
                        <td>
                            Conta
                        </td>
                        <td>
                            Sobre a  criação de contas,
                            forma de usar, e algumas limitações.
                        </td>
                        <td>
                            Regulamento
                        </td>
                    </tr>
                    <tr onClick={SetView.bind(this, "recoveryPass")}>
                        <td>
                            Mudança de senha
                        </td>
                        <td>
                            Nossa aplicação tem uma forma 
                            peculiar em relação a conta, 
                            por isso veja Sobre de a mudança da senha.
                        </td>
                        <td>
                            Documentação
                        </td>
                    </tr>
                    <tr onClick={SetView.bind(this, "dashboard")}>
                        <td>
                            Gerenciar renda
                        </td>
                        <td>
                            Aprenda a usar o gerenciador de rendas.
                            E principalmente compreender os gráficos.
                        </td>
                        <td>
                            Reg. & Doc.
                        </td>
                    </tr>
                    <tr onClick={SetView.bind(this, "plans")}>
                        <td>
                           Orçamentos/Planejamentos
                        </td>
                        <td>
                            Aprenda a usar a função de planejamentos.
                        </td>
                        <td>
                            Doc.
                        </td>
                    </tr>
                    {
                        view !== "main" && <tr onClick={SetView.bind(this, "main")}>
                            <td>
                                Fechar conteúdos
                            </td>
                            <td>
                                Feche o tópico
                            </td>
                            <td>
                                Botão de ação
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
            <small>Clique para visualizar</small>

            {
                (()=> {
                    switch (view){
                        case 'account':
                            return <Account/>
                        case "recoveryPass":
                            return <RecoveryPass/>
                        case "dashboard":
                            return <DashboardInfo/>
                        case "plans":
                            return <Plans/>
                        default: return <></>
                    }
                })()
            }

        </section>
    )
} 