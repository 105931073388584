

export default function DownloadButton({file, name, info, version}){
    return (
        <div className="flex column-f f-center cardDownload">
            <p className="content">
                {info}
            </p>
            <button 
                onClick={()=>{
                    const link = document.createElement("a");
                    link.setAttribute("href", file)
                    link.setAttribute("download", name);
                    link.click()
                    link.remove()
                }}
                className="download">
                    Download {version}
            </button>
        </div>
    )
}