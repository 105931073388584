import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./components/css/main.css";
import { Menu } from "./components/layouts/Menu";
import NotFoundPage from "./components/pages/404";
import ActiveAccount from "./components/pages/Active";
import Beta from "./components/pages/Beta";
import Credits from "./components/pages/Credits";
import { Home } from "./components/pages/Home";
import Policy from "./components/pages/Policy";
import Recovery from "./components/pages/Recovery";

function App() {

  
  return (
    <Router>
      <main id="app">
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/credits" element={<Credits/>}/>
          <Route path="/policy" element={<Policy/>}/>
          <Route path="/beta" element={<Beta/>}/>
          <Route path="/recoveryPass/" element={<Recovery/>}/>
          <Route path="/recoveryPass/:id" element={<Recovery/>}/>
          <Route path="/recoveryPass/:id/:code" element={<Recovery/>}/>
          <Route path="/active/" element={<ActiveAccount/>}/>
          <Route path="/active/:id" element={<ActiveAccount/>}/>
          <Route path="/active/:id/:code" element={<ActiveAccount/>}/>
          <Route path="*" element={<NotFoundPage/>}/>
        </Routes>
      </main>
      <Menu/>
    </Router>
  );
}

export default App;
