import {AiFillWarning} from "react-icons/ai";


export default function NotFoundPage(){
    return (
        <div className="sectionCredits">
            <h2 className="flex f-center">Erro 404 <AiFillWarning style={{margin: 5}} fill="#ff6666" /></h2>
            <p>
                Este erro ocorre quando o usuário solicita uma página inexistente, ou removida da aplicação.
            </p>
        </div>
    )
}