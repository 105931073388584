import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {initializeApp} from "firebase/app"
import {getAnalytics} from "firebase/analytics"
const root = ReactDOM.createRoot(document.getElementById('root'));


const firebaseConfig = {
  apiKey: "AIzaSyDOEh77BuEj1kCwDZ4S4zA1EQ-_5mHgk1g",
  authDomain: "figestor-web.firebaseapp.com",
  projectId: "figestor-web",
  storageBucket: "figestor-web.appspot.com",
  messagingSenderId: "757079370883",
  appId: "1:757079370883:web:5a2a4eb26fcb9875923fb6",
  measurementId: "G-MYLJFRPN6S"
};

// // Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);



root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
