

export default function Credits(){

    async function Copy(e){
        const mainText = e.target.outerText?.split(":")[1].trim();

        try {
            await navigator.clipboard.writeText(mainText)
            alert("Item copiado!")
        } catch (error) {}
    }

    return (
        <section className="sectionCredits">
            <p className="content">
                Este app está sendo elaborado e desenvolvido por <a href="https://instagram.com/jeff.silva_souza" target={"_blank"} rel="noreferrer">Jefferson S De Souza</a>, como anunciado na página inicial, apenas um desenvolvedor trabalhando no projeto.
            </p>
            <p className="flex f-center">
                &copy; <a  rel="noreferrer" href="https://instagram.com/jeff.silva_souza" target={"_blank"}>Jefferson S De Souza</a>/<a rel="noreferrer" href="https://instagram.com/fox.developer" target={"_blank"}>Fox Developer</a>
            </p>

            <p className="content">
                Caso queira fazer alguma doação veja as informações abaixo:
            </p>
            <div className="donatePay">
                <img src={require("../../img/qrcode-pix.png")}/>
                <p>
                    <strong>Nome</strong>: Jefferson Silva de Souza
                    <br/>
                    <strong>Tipo de Chave</strong>: Telefone
                    <br/>
                    <span onClick={Copy}>
                        <strong>Chave PIX</strong>: (74) 99911-7471
                    </span>
                    <br/>
                    <span onClick={Copy}>
                        <strong>Código QrCode</strong>:
                    00020126360014BR.GOV.BCB.PIX0114+55749991174715204000053039865802BR5924Jefferson Silva de Souza6008Itatiaia62070503***6304169D
                    </span>
                </p>
            </div>
        </section>
    )
} 