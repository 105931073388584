import { Link, useLocation } from "react-router-dom";
import Style from "../module/Menu.module.css";


export default function IconsMenu({elm, path, label}){
    const location = useLocation();

    return (
        <Link to={path}>
            <div className={`${Style.cardIcon} ${location.pathname === path && Style.iconActive} flex f-center column-f `}>
                {elm}
                
                <p className={Style.label}>
                    {label}
                </p>
            </div>
        </Link>
    )
}