import Style from "../module/Menu.module.css";
import { FaRegNewspaper } from "react-icons/fa";
import { BiAlignJustify, BiHome } from "react-icons/bi";
import IconsMenu from "../fragments/IconsMenu";

export function Menu(){

    return (
        <header className={`flex f-center ${Style.menu}`}>
            <IconsMenu
                label="Créditos"
                elm={<BiAlignJustify/>}
                path="/credits"
            />

            <IconsMenu
                label="Inicio"
                elm={<BiHome/>}
                path="/"
            />

            <IconsMenu
                label="Doc."
                elm={<FaRegNewspaper/>}
                path="/policy"
            />
        </header>
    )
}